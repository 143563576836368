import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import MaxWidth from 'components/functional/maxWidth'
import {media} from 'utils/Media'
import SingleVenue from './singleVenue'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }

  .wrapper {
    margin: 2rem 0;
  }
`

const TourDates = ({data}) => (
    <MaxWidth $wooden>
        <Wrapper>
            <h1 id="tour-dates">Tour Dates</h1>
            <div className="wrapper">
                {data.map((venue, i) => {
                    //* Look to see if the show is still running + one day
                    const isStillRunning = !moment(venue.lastDate)
                        .add(1, 'days')
                        .isBefore()
                    //* Conditionally render Venue
                    return isStillRunning ? <SingleVenue data={venue} key={i}/> : ''
                })}
            </div>
        </Wrapper>
    </MaxWidth>
)

export default TourDates

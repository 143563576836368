import React from 'react'
import styled from 'styled-components'
import MaxWidth from 'components/functional/maxWidth'
import { media } from 'utils/Media'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0;
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }
  .wrapper {
    margin: 2rem 0;
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }
`

const AboutSection = ({ data }) => (
  <MaxWidth $wooden>
    <Wrapper>
      <h1 id="about">About</h1>
      <div className="wrapper">
        <p>
          The Mousetrap is the genre-defining murder mystery from Agatha
          Christie, the world&rsquo;s best-selling novelist of all time. As news
          spreads of a murder in London, a group of seven strangers find
          themselves snowed in at Monkswell Manor, a remote countryside
          guesthouse. When a police sergeant arrives, the guests discover
          &ndash; to their horror &ndash; that a killer is in their midst. One
          by one, the suspicious characters reveal their sordid pasts. Which one
          is the murderer? Who will be their next victim?
        </p>
        <p>
          The 70th anniversary tour opened at the Theatre Royal Nottingham,
          where it held its original world premiere in 1952. The iconic thriller
          is now visiting over 70 venues across the country, including all
          cities to which it originally played 70 years ago, plus many more.
        </p>
        <p>
          This 70th Anniversary tour marks the first of many ways that The
          Mousetrap will be celebrating its extraordinary milestone year.
        </p>
        <p>
          In the truly ensemble piece where everyone is a suspect Todd Carty
          (EastEnders, Grange Hill, Dancing on Ice) plays Major Metcalf, Hollie
          Sullivan (Barefoot in the Park (Vienna&rsquo;s English Theatre) will
          appear as Mollie Ralston, Barnaby Jago (Shakuntala Devi Human Computer
          -Sony) as Giles Ralston, Shaun McCourt (Blood Brothers UK Tour, War
          Horse) as Christopher Wren, Judith Rae (Switzerland -Ambassadors
          Theatre, West End) as Mrs Boyle, Amy Spinks (Shakespeare Nation -RSC)
          as Miss Casewell, Steven Elliott (The Crown, Holby City) as Mr
          Paravicini and Michael Ayiotis (Salty in John Godber&rsquo;s Teechers
          &rsquo;23 (UK Tour) as Detective Sgt. Trotter. This casting is for
          2023 venues.
        </p>
        <p>
          The 70th Anniversary tour of The Mousetrap continues to visit towns
          and cities around the country finishing on 3rd August 2024 in Windsor
          Theatre Royal.&nbsp;
        </p>
        <p>
          Following its 1952 premiere touring production, The Mousetrap opened
          in the West End where still to this day, it continues its
          record-breaking engagement at the St Martin&rsquo;s Theatre having
          been performed there over 29,500 times, selling over 10 million
          tickets.
        </p>
      </div>
    </Wrapper>
  </MaxWidth>
)

export default AboutSection

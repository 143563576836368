import React from 'react'
import styled from 'styled-components'
import MaxWidth from 'components/functional/maxWidth'
import {media} from 'utils/Media'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }

  .wrapper {
    margin: 2rem 0;
    font-size: 1.5rem;
    @media ${media.sm} {
      display: flex;
      justify-content: center;
    }
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }

  a {
    padding: 0.5rem 0;
    display: block;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;

    @media ${media.sm} {
      display: inline-block;
      padding: 0 1rem;
      margin: 0 0.25rem;
    }
  }

  a:hover {
    color: var(--main);
  }
`

const MenuSection = ({data}) => (
    <MaxWidth>
        <Wrapper>
            <div className="wrapper">
                <a href="#tour-dates">Tour Dates</a>
                <span className="d-none d-sm-inline-block"> | </span>
                <a href="#about">About</a>
                <span className="d-none d-sm-inline-block"> | </span>
                <a href="#cast-and-creative">Cast & Creative</a>
            </div>
        </Wrapper>
    </MaxWidth>
)

export default MenuSection

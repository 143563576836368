import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { media } from 'utils/Media'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CrossIcon from 'svgs/close.svg'
import GetImage from 'components/functional/getImage'

const CollapseContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: 0 auto 3rem 0;
  padding-bottom: 0;

  @media ${media.sm} {
    text-align: center;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
`

const CollapseItem = styled.div`
  width: 100%;
  padding: 0.25rem 0;
  .aside {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-weight: 300;
  }
  @media ${media.sm} {
    max-width: 400px;
  }
  @media ${media.md} {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    width: 50%;
  }
  @media ${media.lg} {
    width: 33.3%;
    padding-bottom: 2rem;
  }

  .arrowDown {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    background: url('${CrossIcon}') no-repeat center center;
    background-size: 100%;
    transition: transform 0.2s linear;
  }

  &.active {
    .arrowDown {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`

const CollapseItemTrigger = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;

  @media ${media.md} {
    min-height: 450px;
  }
  span {
    text-align: center;
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }
  strong {
    /* color: var(--main); */
  }
  .role {
    color: var(--main);
    max-width: 265px;
    margin: var(--auto);
    line-height: 1.5rem;
    text-align: center;
  }
  padding: 3px;
  @media ${media.md} {
    flex-wrap: wrap;
  }

  .trigger-helper {
    padding: 5px;
    transition: background 0.2s linear, color 0.2s linear;
    width: 100%;
  }

  &:hover {
    text-decoration: none;

    padding: 3px;

    @media ${media.sm} {
    }

    .trigger-helper {
      padding: 5px;

      @media ${media.sm} {
      }
    }
  }
`

const CollapseTriggerTitle = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  min-height: 45px;

  flex-wrap: wrap;
  align-items: center;

  @media ${media.sm} {
    padding-right: 0;
    min-height: 92px;
  }
  span {
    transition: opacity 0.4s ease;
    opacity: 1;
    display: block;
    width: 100%;
    line-height: 1;
    &.role {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .gatsby-image-wrapper {
    display: block;
    max-width: 270px;
    height: 100%;
    width: 100%;
    margin: var(--auto);
    @media ${media.sm} {
      width: 100%;
    }
  }
`

const CollapseItemContent = styled.div`
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  border-bottom: solid 1px rgba(255, 255, 255, 0.25);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: wrap;
  justify-content: center;
  transition: height 0.3s ease;

  width: calc(100% - 30px);
  margin: 0 0 2rem;

  @media ${media.sm} {
    width: 100%;
    margin: 0 auto 2rem;
  }
  .active & {
    visibility: visible;
  }
`

const CollapseItemInner = styled.div`
  text-align: center;
  padding: 1rem 1rem 4rem;
  @media ${media.ls} {
    padding-bottom: 2.5rem;
  }
  strong {
    font-size: 1.25rem;
  }
  @media ${media.sm} {
    padding: 3rem 1.5rem 6rem;
  }
  @media ${media.md} {
    padding: 3rem 1.5rem 3rem;
  }
  .collapse-content {
    margin: 0 auto;
    max-width: 900px;
  }
  div {
    p {
      margin: 1rem 0;
    }
    a {
      font-size: 1.1rem;
      color: var(--main);
    }
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CollapseClose = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 1rem;
  right: 0%;
  height: 20px;
  width: 20px;
  transform: translateX(-50%);
  &:after,
  &:before {
    content: '';
    width: 3px;
    height: 60%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media ${media.md} {
    right: 3rem;
    widows: 30px;
    height: 30px;
  }
  @media ${media.xl} {
    right: 5rem;
    widows: 40px;
    height: 40px;
  }
`

class CustomCollapse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeId: '',
      contentHeight: 0,
    }
    this.clickHandler = this.clickHandler.bind(this)
  }

  clickHandler(e, id, type) {
    e.preventDefault()

    if (this.state.activeId === id) {
      this.setState({
        activeId: '',
        contentHeight: 0,
      })
    } else {
      const trigger = e.currentTarget
      // const container = trigger.parentElement;
      const content = trigger.nextSibling
      const inner = content.children[0]
      const height = inner.offsetHeight

      this.setState({
        activeId: id,
        contentHeight: height,
      })

      const collapseItem = document.getElementById(`collapse${id}${type}`)
      const navHeight = 150
      // Wait for previous to close, the scroll to
      //   setTimeout(() => {
      //     window.scrollTo({
      //       top: collapseItem.offsetTop - navHeight,
      //       behavior: 'smooth',
      //     })
      //   }, 500)
    }
  }

  render() {
    const items = this.props.data
    const collapseItems = items.map((item, i) => {
      if (this.props.type === 'ensemble') {
        if (i % 2 === 1) {
          console.log('break')
          return (
            <>
              <CustomCollapseItem
                key={i}
                id={i}
                data={item}
                clickHandler={this.clickHandler}
                type={this.props.type}
                activeId={this.state.activeId}
                contentHeight={
                  this.state.activeId === i ? this.state.contentHeight : 0
                }
                activeClass={this.state.activeId === i ? 'active' : ''}
              />
              <div className="break" />
            </>
          )
        }
        return (
          <CustomCollapseItem
            key={i}
            id={i}
            data={item}
            clickHandler={this.clickHandler}
            type={this.props.type}
            activeId={this.state.activeId}
            contentHeight={
              this.state.activeId === i ? this.state.contentHeight : 0
            }
            activeClass={this.state.activeId === i ? 'active' : ''}
          />
        )
      }
      return (
        <CustomCollapseItem
          key={i}
          id={i}
          data={item}
          clickHandler={this.clickHandler}
          type={this.props.type}
          activeId={this.state.activeId}
          contentHeight={
            this.state.activeId === i ? this.state.contentHeight : 0
          }
          activeClass={this.state.activeId === i ? 'active' : ''}
        />
      )
    })

    return (
      <CollapseContainer fluid className="px-0">
        <Row className="justify-content-center w-100 mx-0">{collapseItems}</Row>
      </CollapseContainer>
    )
  }
}

export default CustomCollapse

class CustomCollapseItem extends Component {
  render() {
    return (
      <CollapseItem
        className={`${this.props.activeClass} ${this.props.type}`}
        style={{ marginBottom: `${this.props.contentHeight}px` }}
      >
        <CollapseItemTrigger
          onClick={e =>
            this.props.clickHandler(e, this.props.id, this.props.type)
          }
          onKeyPress={e =>
            this.props.clickHandler(e, this.props.id, this.props.type)
          }
          tabIndex="0"
        >
          <div className="arrowDown d-sm-none" />
          <div className="trigger-helper">
            <CollapseTriggerTitle>
              <div>
                <GetImage
                  data={this.props.data.image}
                  alt={`${this.props.data.name} | The Mousetrap`}
                />

                <div>
                  <div>
                    <span>
                      <strong>{this.props.data.name}</strong>
                    </span>
                    {/* {this.props.data.understudy ? (
                      <p className="role">Understudy</p>
                    ) : (
                      ''
                    )} */}
                    <span className="role">{this.props.data.role}</span>
                    {this.props.data.aside ? (
                      <p className="aside">{this.props.data.aside}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </CollapseTriggerTitle>
          </div>
        </CollapseItemTrigger>
        <CollapseItemContent
          style={{ height: `${this.props.contentHeight}px` }}
          id={`collapse${this.props.id}${this.props.type}`}
        >
          <CollapseItemInner>
            {/* <img
              src={
                this.props.data.image.childImageSharp.gatsbyImageData.images
                  .fallback.src
              }
              alt={this.props.data.name}
              className="d-block d-sm-none mx-auto mb-4"
            /> */}
            <p>
              <span>
                <strong>{this.props.data.name}</strong>
              </span>
            </p>
            <div
              className="collapse-content"
              dangerouslySetInnerHTML={{ __html: this.props.data.bio }}
            />
            <CollapseClose
              className="c-collapse__item-content-close"
              onClick={e =>
                this.props.clickHandler(e, this.props.id, this.props.type)
              }
            >
              <span className="sr-only">Close</span>
            </CollapseClose>
          </CollapseItemInner>
        </CollapseItemContent>
      </CollapseItem>
    )
  }
}

import GetImage from 'components/functional/getImage'
import React from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'
import moment from 'moment'

const SingleVenueStyles = styled.section`
  width: 100%;
  border-bottom: solid 2px var(--white);

  .venue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.85rem 0;
    @media ${media.ls} {
      gap: 1rem;
      padding: 0.85rem;
      flex-direction: row;
    }

    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__mobile {
        @media ${media.ls} {
          align-items: flex-start !important;
          justify-content: flex-start;
        }

        @media ${media.lg} {
          display: none;
        }
      }

      &__desktop {
        display: none;
        @media ${media.lg} {
          display: block;
          display: flex;
          gap: 0.25rem;
        }
        @media ${media.lg} {
          align-items: center;
        }
      }

      p {
        font-size: 0.95rem;
        font-style: italic;
        @media ${media.ls} {
          align-items: flex-start !important;
          font-size: 0.85rem;
        }
        @media ${media.md} {
          font-size: 1.1rem;
          /* flex: 1 1 300px; */
        }
        @media ${media.lg} {
          font-size: 1.15rem;
        }
      }

      strong {
        text-transform: uppercase;
        font-size: 1rem;
        text-align: center;
        @media ${media.sm} {
          font-size: 1.25rem;
        }
        @media ${media.ls} {
          text-align: left;
        }
        @media ${media.lg} {
          font-size: 1.4rem;
        }
      }

      @media ${media.ls} {
        align-items: flex-start !important;
      }
      @media ${media.lg} {
        display: grid;
        grid-template-columns: 300px 1fr;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        max-width: 150px;
        min-width: 150px;
        padding: 0.15rem 0.25rem;
        width: 100%;

        span {
          font-size: 12px;
        }

        @media ${media.ls} {
          width: 120px;
          max-width: 120px;
          min-width: 120px;
          padding: 0.25rem 0.25rem;
          span {
            font-size: 0.85rem;
          }
        }
        @media ${media.md} {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
          padding: 0.3rem 0;
          span {
            font-size: 1rem;
          }
        }
        @media ${media.lg} {
          width: 200px;
          span {
            font-size: 1.3rem;
          }
        }
      }
    }

    @media ${media.md} {
      padding: 1.5rem 0.85rem;
      display: flex;
      align-items: center;
    }
  }
`

const SingleVenue = ({data}) =>
    data.display === true ? (
        <SingleVenueStyles>
            <div className="venue">
                <div className="text">
                    <p className="text__mobile">{`${data.startDate} - ${data.endDate}`}</p>
                    <div className="text__desktop">
                        <p>{`${data.startDate} -`}</p>
                        <p>{`${data.endDate}`}</p>
                    </div>
                    <strong>{data.title}</strong>
                </div>
                <div className="buttons">
                    {data.onSale ? (
                        <a href={data.url} target="_blank" rel="noreferrer">
                            <button type="button" className="btn btn-main">
                                <span>BOOK</span>
                            </button>
                        </a>
                    ) : (
                        <button type="button" className="btn btn-main no-animation">
                            <span>{data.altBtnText}</span>
                        </button>
                    )}
                </div>
            </div>
        </SingleVenueStyles>
    ) : (
        ''
    )

export default SingleVenue

import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import GetImage from 'components/functional/getImage'

const GalleryWrapperStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 3rem 0;
  z-index: 2;

  .wrapper {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;

    div {
      /* cursor: pointer; */
    }

    @media ${media.md} {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
    }
  }

  .SRLContainer {
    .SRLElementContainer {
      .SRLElementWrapper {
        margin-top: 20rem !important;
      }
    }
  }
`
const GalleryWrapper = ({ data }) => (
  <GalleryWrapperStyles>
    {/* <SimpleReactLightbox>
        <SRLWrapper> */}
    <div className="wrapper this-is-test">
      {data.images.nodes.map((image, i) => (
        <div key={i}>
          <GetImage data={image.image.src} alt={image.image.caption} />
        </div>
      ))}
    </div>
    {/* </SRLWrapper>
      </SimpleReactLightbox> */}
  </GalleryWrapperStyles>
)

export default GalleryWrapper

import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/global/layout'
import TourDates from 'components/index/tourDates'
import Menu from 'components/index/menu'
import About from 'components/index/about'
import Cast from 'components/index/cast'
import Gallery from 'components/index/gallery'
import ButtonSection from 'components/index/buttonSection'
import PageHeader from 'components/shared/pageHeader'

const IndexPage = ({ data }) => {
  const venues = data.Venues.nodes
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  return (
    <Layout>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <Menu />
      <ButtonSection />
      <TourDates data={venues} />
      <About />
      <Cast />
      <Gallery />
    </Layout>
  )
}

export default IndexPage

export const VenueQuery = graphql`
  query {
    Venues: allDatesJson {
      nodes {
        title
        startDate
        endDate
        lastDate
        url
        onSale
        altBtnText
        display
      }
    }
    mobHeaderImg: file(name: { eq: "mt-tour-header-cropped" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "mt-tour-header" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

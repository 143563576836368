import React from 'react'
import styled from 'styled-components'
import Cast from 'components/cast/cast'
import Creative from 'components/cast/creative'
import MaxWidth from 'components/functional/maxWidth'
import {media} from 'utils/Media'
import {graphql, StaticQuery} from 'gatsby'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0 0;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }

  .wrapper {
    margin: 2rem 0 0;
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }
`

const CastSection = props => (
  <MaxWidth $wooden>
    <Wrapper>
      <h1 id="cast-and-creative">Cast and Creative</h1>
      <div className="wrapper">
        <Cast data={props.data.cast.nodes[0]}/>
        <Creative data={props.data.creative.nodes}/>
      </div>
    </Wrapper>
  </MaxWidth>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        cast: allCastJson {
          nodes {
            mainCast {
              name
              role
              image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
              bio
            }
            understudy {
              name
              role
              image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
              }
              bio
              understudy
            }
          }
        }
        creative: allCreativeJson {
          nodes {
            name
            role
            bio
          }
        }
      }
    `}
    render={data => <CastSection data={data}/>}
  />
)

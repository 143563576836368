import React from 'react'
import styled from 'styled-components'
import MaxWidth from 'components/functional/maxWidth'
import { media } from 'utils/Media'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0;
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }
  .wrapper {
    margin: 0 0 2rem;
  }
  
  p {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  button {
    width: 300px;
    max-width: 100%;
  }
`

const AboutSection = ({ data }) => (
    <MaxWidth>
        <Wrapper>
            <div className="wrapper">
                <p className="mx-auto text-center">
                    <a href="https://uk.the-mousetrap.co.uk/">
                        <button type="button" className="btn btn-main">
                            <span>Visit the Mousetrap West End website</span>
                        </button>
                    </a>
                </p>
            </div>
        </Wrapper>
    </MaxWidth>
)

export default AboutSection

import React from 'react'
import styled from 'styled-components'
import MaxWidth from 'components/functional/maxWidth'
import GalleryWrapper from 'components/shared/galleryWrapper'
import { media } from 'utils/Media'
import { graphql, StaticQuery } from 'gatsby'

const Wrapper = styled.section`
  width: 100%;
  padding: 1rem 0 0;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    @media ${media.md} {
      font-size: 3.5rem;
    }
  }

  h4 {
    text-align: center;
  }

  .wrapper {
    margin: 2rem 0 0;
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }
`

const Gallery = ({ data }) => (
  <MaxWidth $wooden>
    <Wrapper>
      <h1 id="gallery">Gallery</h1>
      <h4>2023 TOUR CAST</h4>
      <div className="wrapper">
        <GalleryWrapper data={data} />
        <strong className="accreditation">
          Cast photography by Matt Crockett
        </strong>
      </div>
    </Wrapper>
  </MaxWidth>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        images: allGalleryJson {
          nodes {
            image {
              caption
              src {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Gallery data={data} />}
  />
)
